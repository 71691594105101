const cloud = {
    general: {
        onboarding: 'عملية التسجيل',
    },
    create_tenant: {
        page_title: 'إنشاء مستأجر',
        title: 'أنشئ أول مستأجر لك',
        description: 'المستأجر هو بيئة معزولة حيث يمكنك إدارة هويات المستخدمين والتطبيقات وجميع الموارد الأخرى في Logto.',
        invite_collaborators: 'ادعو مشاركيك عبر البريد الإلكتروني',
    },
    social_callback: {
        title: 'لقد قمت بتسجيل الدخول بنجاح',
        description: 'لقد قمت بتسجيل الدخول بنجاح باستخدام حسابك الاجتماعي. لضمان التكامل السلس والوصول إلى جميع ميزات Logto، نوصي بمتابعة تكوين موصلك الاجتماعي الخاص بك.',
        /** UNTRANSLATED */
        notice: "Please avoid using the demo connector for production purposes. Once you've completed testing, kindly delete the demo connector and set up your own connector with your credentials.",
    },
    tenant: {
        create_tenant: 'إنشاء مستأجر',
    },
};
export default Object.freeze(cloud);
